import React from "react";

import Divider from "./Divider";

const About = () => {

    const about_title = "ABOUT THE ARTIST";

    return (
        <div className="section">
            <div className="container">
                <Divider title={about_title} size="h4"/>
                <section>
                    <div className="row">
                        <div className="col s12 m12 l12 center scrollspy" id="about">
                            <img src="/img/aboutimg.jpg" className="resizeImage circle responsive-img" alt="Timea Ludnai"/>
                        </div>
                        <div className="col s12 m12 l12">
                            <blockquote className="flow-text justify">
                                Timea Ludnai is a contemporary fine artist and published author, living in Germany. She
                                was
                                born
                                in
                                1979,
                                in Timisoara, a city of vast cultural and artistic values and traditions in western
                                Romania. <br/>
                                Encouraged
                                by her middle school art teacher, Timea started her journey as an artist at an early
                                age.
                                She
                                attended
                                the Fine Art School in Timisoara, Romania, where she also received her high school
                                diploma
                                and
                                her
                                professional certificate as a visual communications artist.<br/> Her studies in psychology,
                                classic
                                philosophy and metaphysics, combined with her enthusiasm for color and texture are
                                defining
                                elements of
                                Timea’s artwork, urging her to move swiftly between conventional and unexpected media.
                                Same as her
                                writing, Timea’s artwork is meant to both enchant the senses and invite to
                                contemplation.
                            </blockquote>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default About