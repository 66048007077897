import React, {useEffect} from 'react';
import Divider from "../layout/Divider";
import {ReCAPTCHA} from "react-google-recaptcha";
import ContactForm from "./ContactForm";




const Contact = () => {
    const divider_title = "CONTACT ME";
    const divider_content = "Regarding further inquiries on my work, feel free to use the provided contact form below in order to get in touch."

    return (
        <section id="contact" className="mt-3 section scrollspy">
            <div className="container">
                <Divider title={divider_title} content={divider_content} size="h4"/>
                <div className="row">
                    <ContactForm />
                </div>
            </div>
        </section>
    )
};

export default Contact