import React, { useState, useCallback, useEffect, Suspense } from 'react';

import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';

import LandingPage from "./components/LandingPage";
import Navbar from "./components/layout/Navbar";
import {AuthContext} from "./context/auth-context";
import LoadingSpinner from "./util/LoadingSpinner";

let logoutTimer;

const AdminPage = React.lazy(() => import('./components/admin/AdminPage'));
const LoginPage = React.lazy(() => import('./components/auth/LoginPage'));
const ImageGallery = React.lazy(() => import('./components/portfolio/ImageGallery'));

const App = () => {

    const [token, setToken] = useState(false);
    const [userId, setUserId] = useState(false);
    const [tokenExpirationDate, setTokenExpirationDate] = useState();


    // not production ready, no auth yet

    const login = useCallback((uid, token, expirationDate) => {
        setToken(token);
        setUserId(uid);
        const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000*60*60);
        setTokenExpirationDate(tokenExpirationDate);
        localStorage.setItem('userData', JSON.stringify({
            userId: uid,
            token: token,
            expiration: tokenExpirationDate.toISOString()
        }));
    }, []);

    const logout = useCallback(() => {
       setToken(null);
       setUserId(null);
       setTokenExpirationDate(null);
       localStorage.removeItem('userData');
    }, []);

    useEffect( () => {
        if(token && tokenExpirationDate) {
            const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if(storedData && storedData.token && new Date(storedData.expiration) > new Date()){
            login(storedData.userId, storedData.token, new Date(storedData.expiration));
        }
    }, [login]);

    return (
        <AuthContext.Provider value={
            {
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                login: login,
                logout: logout
            }
        }>
            <Router>
                <main className="App">
                    <Suspense fallback={
                        <div className="center mt-7">
                            <LoadingSpinner/>
                        </div>
                    }>
                        <Route path="/ursus/login" exact>
                            <LoginPage />
                        </Route>

                        <Switch>
                            <Route path="/" exact>
                                <Navbar type="default"/>
                                <LandingPage />
                            </Route>
                            <Route path="/ursus" exact>
                                <Navbar type="admin" expirationDate={tokenExpirationDate}/>
                                <AdminPage />
                            </Route>
                            <Route path="/portfolio" exact>
                                <Navbar type="default"/>
                                <ImageGallery />
                            </Route>
                        </Switch>
                    </Suspense>
                </main>
            </Router>
        </AuthContext.Provider>
    )
};

export default App;
