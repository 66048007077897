import React, { useEffect } from "react";

const Header = () => {
    return (
        <header id="header" className="shift-up">
            <div className="parallax-container valign-wrapper" >
                <div className="parallax center">
                    <picture>
                        <source media="(max-width: 450px)" srcSet="/img/header_mobile.jpg"/>
                        <img src="img/dark_header_alt-min.jpg" alt="Header Image"/>
                    </picture>

                </div>
                <div className="overlay center animate__animated animate__fadeIn">
                    <h3 className="white-text custom-size">"My need to create is only surpassed by my need to love."</h3>
                    <h5 className="white-text">Timea Ludnai</h5>
                </div>
            </div>
        </header>
    )
};

export default Header