import React from "react";

import Header from "./layout/Header";
import Footer from "./layout/Footer";
import About from "./layout/About";
import Contact from "./contact/Contact";
import Parallax from "./layout/Parallax";
import PortfolioPreview from "./portfolio/PortfolioPreview";
import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.min.css'

const LandingPage = () => {
    return(
        <React.Fragment>
            <ToastContainer/>
            <Header />
            <PortfolioPreview />
            <About />
            <Parallax />
            <Contact />
            <Footer />
        </React.Fragment>
    );
};

export default LandingPage;