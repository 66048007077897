import React from 'react';

const Button = props => {
    return (
        <button className={`btn border-round waves-effect waves-light ${props.color} ${props.className}`}
                type={props.type} name="action"
                disabled={props.disabled}
                onClick={null || props.onClick}
        >
            {props.title}
            {props.icon && <i className="material-icons left">{props.icon}</i>}
        </button>
    )
};
export default Button;