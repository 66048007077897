import React, {useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import {AuthContext} from "../../context/auth-context";
import {toast} from "react-toastify";
import M from 'materialize-css';



const handleTimerClick = e => {
    e.preventDefault();
    toast.info(<p>You will automatically be logged out by this time.</p>);
}

const Navbar = props => {
    const auth = useContext(AuthContext);

    useEffect(() => {
        M.ScrollSpy.init(document.querySelectorAll('.scrollspy'));
        M.Sidenav.init(document.querySelectorAll('.sidenav'));
    });

    if (auth.isLoggedIn && props.type === "admin") {
        return (
            <nav className="cyan darken-1">
                <div className="nav-wrapper container">
                    <Link to="/dashboard" className="brand-logo">
                        Dashboard v.1
                    </Link>
                    <a href="#mobile_menu" className="sidenav-trigger" data-target="mobile-links">
                        <i className="material-icons">menu</i>
                    </a>
                    <ul className="right hide-on-med-and-down">
                        <li><Link to="/">Landing Page</Link></li>
                        {props.expirationDate ? (
                            <li className="active"><a onClick={handleTimerClick}>{props.expirationDate.toLocaleTimeString()}</a></li>
                        ) : (
                            <li><p>"Failed to fetch time."</p></li>
                        )}

                        <li><a className="waves-effect waves-light btn grey darken-3" onClick={auth.logout}>Sign out <i
                            className="material-icons right">logout</i></a></li>
                    </ul>
                </div>
            </nav>
        )
    } else {
        return (
            <nav>
                <div className="navbar-fixed">
                    <nav className="grey darken-3 z-depth-0">
                        <div className="nav-wrapper container">
                            <Link to="/" className="brand-logo">
                                <img src="/img/logo.svg" alt=""/>
                            </Link>
                            <a href="#mobile_menu" className="sidenav-trigger" data-target="mobile-links">
                                <i className="material-icons">menu</i>
                            </a>
                            <ul className="right hide-on-med-and-down">
                                <li><a href="#portfolio">Portfolio</a></li>
                                <li><a href="#about">About</a></li>
                                <li><a href="https://www.etsy.com/shop/VividColors?ref=l2-about-shopname"
                                       className="tooltipped" data-position="bottom" data-tooltip="Etsy Store">Shop</a>
                                </li>
                                <li><a href="#contact">Contact</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <ul className="sidenav" id="mobile-links">
                    <li><a href="#portfolio">Portfolio</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="https://www.etsy.com/shop/VividColors?ref=l2-about-shopname">Shop</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </nav>
        )
    }
};

export default Navbar;