import React, {useEffect} from "react";
import M from 'materialize-css/dist/js/materialize.min.js';


const ImageElement = props => {
    useEffect(() => {
        M.Materialbox.init(document.querySelectorAll('.materialboxed'));
    });

    return (
        <div>
            <div className="card">
                <div className="card-image">
                    <img className="materialboxed" src={`${process.env.REACT_APP_ASSET_URL}/${props.image.image}`} alt={props.image.title}/>
                </div>
                <div className="card-content">
                    <span className="card-title">{props.image.title}</span>
                    <p>{props.image.width + " x " + props.image.height + " cm"}</p>
                </div>
            </div>
        </div>
    )
};


export default ImageElement