import {validate} from "../util/validators";

export const inputReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators)
            };
        case 'CHECKED':
            return {
                ...state,
                value: action.val,
                isValid: true
            }
        case 'TOUCH': {
            return {
                ...state,
                isTouched: true
            };
        }
        case 'RESET': {
            console.log(state);
            return {
                ...state,
                value: action.val,
                isValid: false
            }
        }
        default:
            return state;
    }
};