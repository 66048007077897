import React, {useReducer, useEffect} from 'react';
import {inputReducer} from "../../reducer/inputReducer";
import M from "materialize-css/dist/js/materialize.min";

const Input = props => {

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.value,
        isTouched: false,
        isValid: props.valid || false
    });

    const {id, onInput} = props;
    const {value, isValid} = inputState;

    useEffect(() => {
        onInput(id, value, isValid);
    }, [id, value, isValid, onInput]);

    const changeHandler = e => {
        dispatch({type: 'CHANGE', val: e.target.value, validators: props.validators});
    };

    const checkedHandler = e => {
        dispatch({type: 'CHECKED', val: e.target.checked});
    }

    const touchHandler = () => {
        dispatch({
            type: 'TOUCH'
        })
    }

    const element =
        props.element === 'input' ? (
            <input
                id={props.id}
                type={props.type}
                placeholder={props.placeholder}
                className="validate"
                onChange={props.type === 'checkbox' ? checkedHandler : changeHandler}
                onBlur={touchHandler}
                value={inputState.value || props.value}
            />
        ) : (
            <textarea
                id={props.id}
                rows={props.rows || 3}
                className="materialize-textarea validate"
                onChange={changeHandler}
                onBlur={touchHandler}
                value={inputState.value}
            />
        );
    switch (props.type) {
        case 'text':
            return (
                <div className={props.type === 'text' ? ('input-field ' + props.className) : props.className}>
                    {props.icon && (<i className="material-icons prefix">{props.icon}</i>)}
                    {element}
                    <label htmlFor={props.id}>{props.label}</label>
                    {!inputState.isValid && inputState.isTouched && (
                        <span className="helper-text" data-error={props.errorText}/>
                    )}
                </div>
            )
        case 'checkbox':
            return (
                <div className={props.type === 'text' ? ('input-field ' + props.className) : props.className}>
                    <p>
                        <label>
                            <input
                                id={props.id}
                                type={props.type}
                                className={props.className}
                                onChange={checkedHandler}
                                onBlur={touchHandler}
                                value={inputState.value}/>
                            <span>{props.label}</span>
                        </label>
                    </p>
                    {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
                </div>
            )
        case 'file':
            return (
                <div className="file-field input-field">
                    <div className="btn">
                        <span>File</span>
                        {element}
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path validate" type="text"/>
                    </div>
                </div>
            )
        case 'email':
            return (
                <div className={props.type === 'email' ? ('input-field ' + props.className) : props.className}>
                    {props.icon && (<i className="material-icons prefix">{props.icon}</i>)}
                    {element}
                    <label htmlFor={props.id}>{props.label}</label>
                    {!inputState.isValid && inputState.isTouched && (
                        <span className="helper-text" data-error={props.errorText}/>
                    )}
                </div>
            )
        case 'password':
            return(
                <div className={props.type === 'password' ? ('input-field ' + props.className) : props.className}>
                    {props.icon && (<i className="material-icons prefix">{props.icon}</i>)}
                    {element}
                    <label htmlFor={props.id}>{props.label}</label>
                    {!inputState.isValid && inputState.isTouched && (
                        <span className="helper-text" data-error={props.errorText}/>
                    )}
                </div>
            )
    }
};

export default Input;

