import React, {useRef} from 'react';
import Input from "../form-components/Input";

import {useForm} from "../../hooks/form-hook";
import {useHttpClient} from "../../hooks/http-hook";

import {VALIDATOR_REQUIRE} from "../../util/validators";
import Button from "../form-components/Button";
import LoadingSpinner from "../../util/LoadingSpinner";

import {toast, ToastContainer} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";


const ContactForm = () => {
    const {isLoading, sendRequest} = useHttpClient();
    const captchaRef = useRef();
    const initialInputs = {
        lastName: {
            value: '',
            isValid: false
        },
        firstName: {
            value: '',
            isValid: false
        },
        email: {
            value: '',
            isValid: false
        },
        message: {
            value: '',
            isValid: true
        }
    }

    const [formState, inputHandler] = useForm(initialInputs, false);

    const contactSubmitHandler = async e => {
        e.preventDefault();

        try {
            const token = await captchaRef.current.executeAsync();
            captchaRef.current.reset();

            const body = {
                firstName: formState.inputs.firstName.value,
                lastName: formState.inputs.lastName.value,
                email: formState.inputs.email.value,
                message: formState.inputs.message.value,
                token: token
            }

            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/notifications/new`, "POST", JSON.stringify(body), {'Content-Type': 'application/json'}).then(() => {
            });
            toast.success(<p>Your Message has been sent. Thank you!</p>);

        } catch (err) {
            toast.error(<p>There has been a problem sending Your message. Please try again later.</p>);
        }
    }

    return (
        <form onSubmit={contactSubmitHandler}>
            {isLoading && (
                <div className="center-align">
                    <LoadingSpinner />
                </div>
            )}
            {!isLoading && (
                <React.Fragment>
                    <ToastContainer/>
                    <div className="row">
                        <Input // input width in cm
                            id="firstName"
                            label="First Name"
                            element="input"
                            type="text"
                            validators={[VALIDATOR_REQUIRE()]}
                            className="col s6"
                            onInput={inputHandler}
                        />
                        <Input // input height in cm
                            id="lastName"
                            label="Last Name"
                            element="input"
                            type="text"
                            validators={[VALIDATOR_REQUIRE()]}
                            className="col s6"
                            onInput={inputHandler}
                        />
                    </div>
                    <div className="row">
                        <Input
                            id="email"
                            label="Email"
                            element="input"
                            type="text"
                            validators={[VALIDATOR_REQUIRE()]}
                            className="col s12"
                            errorText="Please enter a valid title."
                            onInput={inputHandler}
                        />
                    </div>
                    <div className="row">
                        <Input
                            id="message"
                            label="Your Message"
                            element="input"
                            type="text"
                            className="col s12"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a valid message."
                            onInput={inputHandler}
                        />
                    </div>
                    <div className="center">
                        <Button type="submit" title="Send" icon="send" disabled={!formState.isValid}/>
                    </div>
                </React.Fragment>
            )}
            <ReCAPTCHA sitekey="6LeMPokbAAAAAJ-PWvxN-0Qsw0z9lUhJBO_WpWwg" ref={captchaRef} size="invisible"/>
        </form>
    )
}
export default ContactForm;