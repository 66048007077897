import React, {useEffect} from "react";
import M from "materialize-css/dist/js/materialize.min";

const Parallax = () => {

    useEffect(() => {
        M.Parallax.init(document.querySelectorAll('.parallax'));
    });

    return (
        <section>
            <div className="parallax-container valign-wrapper">
                <div className="parallax">
                    <img src="/img/atelier.jpeg" alt="parallax_atelier"/>
                </div>
                <div id="parallax" className="overlay container center">
                </div>
            </div>
        </section>
    )
};

export default Parallax