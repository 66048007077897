import React from "react";
import ImageElement from "./ImageElement";
import Masonry from 'react-masonry-css';


const PreviewList = props => {

    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 2,
        500: 1
    };

    return (
        <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
            {props.items && props.items.map(image => {
                return (
                    <ImageElement image={image} key={image.id} id={image.id}/>
                )
            })}
        </Masonry>
    )
};

export default PreviewList