import React from "react";

const Footer = () => {
    return (
        <footer className="page-footer grey darken-3">
            <div className="container">
                <div className="row center">
                    <a href="https://de-de.facebook.com/timealudnai.artist/" className="btn-floating cyan darken-1 waves-effect waves-light social-media-padding"><i className="fab fa-facebook-f fa-2x"></i></a>
                    <a href="#twitter" className="btn-floating cyan darken-1 waves-effect waves-light social-media-padding"><i className="fab fa-twitter fa-2x"></i></a>
                    <a href="https://www.instagram.com/vividcolors_handmade_jewellery/?hl=de" className="btn-floating cyan darken-1 waves-effect waves-light social-media-padding"><i className="fab fa-instagram fa-2x"></i></a>
                </div>
            </div>
            <div className="footer-copyright center">
                <div className="container">
                    © 2020 Copyright Timea Ludnai. All rights reserved.
                </div>
            </div>
        </footer>
    )
}

export default Footer