import React, {useState, useEffect} from "react";
import {useHttpClient} from "../../hooks/http-hook";
import {toast} from "react-toastify";
import PreviewList from "./PreviewList";
import {Link} from "react-router-dom";
import Divider from "../layout/Divider";
import LoadingSpinner from "../../util/LoadingSpinner";

const PortfolioPreview = () => {
    const [loadedImages, setLoadedImages] = useState();
    const {sendRequest, isLoading, error, clearError} = useHttpClient();

    useEffect(() => {
        const fetchPaintings = async () => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/paintings/preview`, 'GET');
                setLoadedImages(responseData.paintings);
            } catch (err) {
                toast.error(<p>Failed to load images.</p>);
            }
        };
        fetchPaintings();
    }, [sendRequest]);

    return (
        <div className="container center">
            {!isLoading && (
                <React.Fragment>
                    <Divider title="PORTFOLIO" size="h4"/>
                    <div id="portfolio" className="section scrollspy row">
                        <PreviewList items={loadedImages}/>
                    </div>
                    <div className="row">
                        <Link to="/portfolio" className="cyan darken-2 waves-effect waves-light btn border-round">See
                            more</Link>
                    </div>
                </React.Fragment>
            )}
            {isLoading && (
                <div className="mt-3">
                    <LoadingSpinner/>
                </div>
            )}
        </div>
    )
}

export default PortfolioPreview;