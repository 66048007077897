import React from 'react';

const Divider = props => {
    return (
        <React.Fragment>
            <section className="divider"/>
            {props.size === 'h4' && (<h4 className="center">{props.title}</h4>)}
            {props.size === 'h6' && (<h6 className="center">{props.title}</h6>)}
            {props.content && <p className="center">{props.content}</p>}
            <section className="divider"/>
        </React.Fragment>
    )
}
;

export default Divider;